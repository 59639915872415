import { ReactNode } from 'react';
import {
  Box,
  Container,
  Stack,
  SimpleGrid,
  Text,
  Link,
  Flex,
  VisuallyHidden,
  chakra,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa';
import logoDark from '../../../assets/logo/logotransparent.png';
import Badges1 from '../../../assets/badges/badges (1).png';
import Badges2 from '../../../assets/badges/badges (2).png';
import Badges3 from '../../../assets/badges/badges (3).png';
import Badges4 from '../../../assets/badges/bbb-logo-resized.png';
import facebook from '../../../assets/icons/facebook.png';
import twitter from '../../../assets/icons/twitter.png';
import instagram from '../../../assets/icons/instagram.png';
import './footer.css';
// import AppStoreBadge from '@/components/AppStoreBadge';
// import PlayStoreBadge from '@/components/PlayStoreBadge';

export default function Footer() {

  const facebook_function = () => {
    window.open('https://www.facebook.com/skillsevaluation', '_blank');
  }

  const instagram_function = () => {
    window.open('https://www.instagram.com/skillsevaluation/', '_blank');
  }

  const twitter_function = () => {
    window.location.href = '#';
    // window.open('#', '_blank');
  }

  return (
    <>
      <div className='main_footer_section'>
        <div className='main_footer_div'>
          <div className="main_footer_inner_div1">
            <img src={Badges1} alt="" />
            <img src={Badges2} alt="" />
            <img src={Badges3} alt="" />
            {/* <img src={Badges4} alt="" /> */}
          </div>
          <div className="main_footer_inner_div2">
            <div className='main_footer_inner_heading'> QUICK LINKS </div>
            <div className='main_footer_inner_quick_links'>
              <ul>
                <li><a href='/aboutus'>  ABOUT US </a></li>
                <li><a href='/faq'>  FAQ </a></li>
                <li><a href='#'>  TERMS AND CONDITIONS </a></li>
                <li><a href='/Privacy-Policy'>  PRIVACY POLICY </a></li>
              </ul>
            </div>
            <div className='main_footer_inner_content_div'>
              <b> American Skills Evaluation Institute </b> (ASEI) is the premier provider of services for the evaluation as per US standards of skill certificates obtained globally for the purpose of seeking employment, admission, immigration and visas, licensing boards and certification.
            </div>
          </div>
          <div className="main_footer_inner_div3">
            <div className='main_footer_inner_heading'> CONTACT US </div>
            <div className='main_footer_inner_contant_us'>
              <div>
                <span>PHONE:</span>
                <p>+1 (905) 510-0807</p>
              </div>
              <div>
                <span>EMAIL:</span>
                <p>support@skillsevaluation.org</p>
              </div>
              <div>
                <span>ADDRESS:</span>
                <p>7080 Southwest Fwy
                  Houston, TX 77074
                </p>
              </div>
            </div>
            <div className='main_footer_inner_div3_icons_div'>
              <div className='icon_div'>
                <img src={facebook} alt="" onClick={facebook_function} />
                <img src={twitter} alt="" onClick={twitter_function} />
                <img src={instagram} alt="" onClick={instagram_function} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}